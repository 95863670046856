import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';

import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import mainMenu from '../../config/navigation';
import Button from '../button/button';
import Typography from '../typography/typography';
import Container from '../container/container';
import {
  HeaderBase,
  TopRow,
  BottomRow,
  HeaderGridTop,
  HeaderGridBottom,
  LogoArea,
  Logo,
  EUFlagArea,
  EUFlag,
  HeadingArea,
  ContactArea,
  NavMenu,
  NavItem,
  NavItemDesktop,
  ContactLink,
  Divider,
} from './styles';

const query = graphql`
  query {
    logo: file(name: { eq: "promen-logo" }) {
      childImageSharp {
        fluid(
          maxWidth: 150
          traceSVG: { threshold: 250, color: "hsl(45,93%,52%)" }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    EUFlag: file(name: { eq: "flaga-unii" }) {
      childImageSharp {
        fluid(
          maxWidth: 150
          traceSVG: { threshold: 250, color: "hsl(45,93%,52%)" }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    phoneIcon: file(name: { eq: "phone-icon" }) {
      childImageSharp {
        fixed(width: 15) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    mailIcon: file(name: { eq: "mail-icon" }) {
      childImageSharp {
        fixed(width: 15) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    prismicStronaKontakt {
      data {
        sekretariat {
          telefon_2
          email
        }
      }
    }
  }
`;

const Header = ({ className }) => {
  const {
    logo: {
      childImageSharp: { fluid: logoFluid },
    },
    EUFlag: {
      childImageSharp: { fluid: flagFluid },
    },
    prismicStronaKontakt: {
      data: { sekretariat: sekretariatArr },
    },
  } = useStaticQuery(query);

  const [sekretariat] = sekretariatArr;

  const { telefon_2: phone, email } = sekretariat;

  return (
    <HeaderBase className={className}>
      <TopRow>
        <Container>
          <HeaderGridTop>
            <LogoArea>
              <Link to="/" title="Strona Główna - PRO-MEN" css="width: 100%;">
                <Logo imgStyle={{ objectFit: 'contain' }} fluid={logoFluid} />
              </Link>
            </LogoArea>

            <EUFlagArea>
              <EUFlag fluid={flagFluid} />
            </EUFlagArea>

            <HeadingArea>
              <Typography
                variant="h4"
                css={`
                  font-size: 0.8em;
                  line-height: 1.3em;
                  padding-left: 15px;
                  border-left: 1px solid ${({ theme }) => theme.colors.neutral};
                `}
              >
                KOMPLEKSOWA OBSŁUGA
                <br /> BUDÓW
              </Typography>
            </HeadingArea>

            <ContactArea>
              <NavMenu css="justify-content: flex-end;">
                <NavItemDesktop
                  css={`
                    @media (max-width: 1120px) {
                      display: none !important;
                    }
                  `}
                >
                  <ContactLink href={`tel: ${phone}`}>
                    <PhoneIcon
                      css={`
                        color: ${({ theme }) =>
                          theme.colors.primary} !important;
                        font-size: 1em !important;
                        margin-right: 10px;
                      `}
                    />
                    {phone}
                  </ContactLink>
                </NavItemDesktop>

                <NavItemDesktop>
                  <ContactLink href={`mailto: ${email}`}>
                    <MailIcon
                      css={`
                        color: ${({ theme }) =>
                          theme.colors.primary} !important;
                        font-size: 1em !important;
                        margin-right: 10px;
                      `}
                    />
                    {email}
                  </ContactLink>
                </NavItemDesktop>

                {/* <NavItem css="margin-left: 0;">
                  <Button component={Link} to="/kontakt/" title="Kontakt">
                    SKONTAKTUJ SIĘ Z NAMI
                  </Button>
                </NavItem> */}

                <NavItem css="margin-left: 0; padding-left: 0 !important;">
                  <Button
                    component={Link}
                    to="/rodo/"
                    title="Polityka prywatności"
                  >
                    Polityka prywatności
                  </Button>
                </NavItem>
              </NavMenu>
            </ContactArea>
          </HeaderGridTop>
        </Container>
      </TopRow>

      <BottomRow>
        <Container>
          <HeaderGridBottom>
            <NavMenu>
              {mainMenu.map(({ label, title, path }, index) => (
                <React.Fragment key={path}>
                  {!!index && <Divider />}

                  <NavItem>
                    <Link
                      to={path}
                      title={title}
                      getProps={
                        path === '/'
                          ? undefined
                          : ({ isPartiallyCurrent }) =>
                              isPartiallyCurrent
                                ? { className: 'item active' }
                                : null
                      }
                      activeClassName="active"
                    >
                      {label}
                    </Link>
                  </NavItem>
                </React.Fragment>
              ))}
            </NavMenu>
          </HeaderGridBottom>
        </Container>
      </BottomRow>
    </HeaderBase>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
