import { css } from 'styled-components';

const theme = {
  colors: {
    primary: 'hsl(40, 99%, 51%)',
    secondary: 'hsl(31, 100%, 47%)',
    neutral: 'hsl(207, 1%, 68%)',
    lightgrey: 'hsl(207, 5%, 91%)',
    text: 'hsl(207, 2%, 28%)',
  },
  breakpoints: {
    xl: 1920,
    l: 1410,
    m: 996,
    s: 768,
    xs: 610,
  },
  animations: {
    default: '300ms ease-in-out',
  },
};

const sizes = theme.breakpoints;

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const mobileFirstMedia = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `min-width: ${sizes[label]}px`;
  return acc;
}, {});

export default theme;
