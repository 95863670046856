import styled from 'styled-components';
import Img from 'gatsby-image';

import { media } from '../../config/theme';

const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};
`;

export const HeaderBase = styled.header`
  width: 100%;
  background: white;
  position: relative;
  z-index: 50;

  a {
    transition: color ${({ theme }) => theme.animations.default};
  }

  ${media.m`
    height: 80px;
  `}
`;

export const TopRow = styled(Row)`
  ${media.m`
    height: 100%;
  `}
`;

export const BottomRow = styled(Row)`
  ${media.m`
    display: none;
  `}
`;

export const HeaderGridTop = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 170px 60px 240px 1fr;
  grid-template-rows: 76px;

  ${media.m`
    grid-template-columns: 90px 50px 135px 1fr;
  `}
`;

export const HeaderGridBottom = styled.div`
  height: 53px;
  display: flex;
  align-items: center;

  ${media.m`
    display: none;
  `}
`;

export const LogoArea = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
`;

export const Logo = styled(Img)`
  max-height: 60px;
`;

export const EUFlagArea = styled.div`
  grid-column: 2 / 3;
  align-self: center;
  padding-top: 6px;
`;

export const EUFlag = styled(Img)`
  max-width: 100%;
`;

export const HeadingArea = styled.div`
  grid-area: 1 / 3 / 2 / 4;
  align-self: center;
  margin-left: 20px;

  ${media.xs`
    margin-left: 15px;
    font-size: 0.9em;
  `}

  @media (max-width: 360px) {
    display: none;
  }
`;

export const NavMenu = styled.ul`
  width: 100%;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
`;

export const NavItem = styled.li`
  line-height: 1.2em;
  height: 1.2em;
  margin: 0 10px;
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text};

  a.active,
  a :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const NavItemDesktop = styled(NavItem)`
  /* @media (max-width: 1150px) {
    display: none !important;
  } */

  ${media.m`
    display: none !important;
  `}
`;

export const ContactArea = styled.div`
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  display: flex;
  align-items: stretch;

  ${NavItem} {
    height: 100%;
    margin: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      border-left: 1px solid ${({ theme }) => theme.colors.lightgrey};
    }
  }

  ${media.m`
    display: none;
  `}
`;

export const ContactLink = styled.a`
  text-transform: none;
  line-height: 1em;
  font-size: 1em;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
`;

export const Divider = styled.span`
  width: 1px;
  height: 1.2em;
  background: ${({ theme }) => theme.colors.neutral};
`;
