const mainMenu = [
  { path: '/', label: 'Strona Główna', title: 'Strona Główna - PRO-MEN' },
  { path: '/o-nas', label: 'O nas', title: 'O nas - PRO-MEN' },
  {
    path: '/rusztowania/',
    label: 'Rusztowania',
    title: 'Rusztowania - PRO-MEN',
  },
  { path: '/szalunki/', label: 'Szalunki', title: 'Szalunki - PRO-MEN' },
  { path: '/drabiny/', label: 'Drabiny', title: 'Drabiny - PRO-MEN' },
  { path: '/ogrodzenia/', label: 'Ogrodzenia', title: 'Ogrodzenia - PRO-MEN' },
  { path: '/akcesoria/', label: 'Akcesoria', title: 'Akcesoria - PRO-MEN' },
  { path: '/kontakt/', label: 'Kontakt', title: 'Kontakt - PRO-MEN' },
];

export const footerMenu = [
  {
    path: '/rusztowania/',
    label: 'Rusztowania',
    title: 'Rusztowania - PRO-MEN',
  },
  { path: '/szalunki/', label: 'Szalunki', title: 'Szalunki - PRO-MEN' },
  { path: '/drabiny/', label: 'Drabiny', title: 'Drabiny - PRO-MEN' },
  { path: '/ogrodzenia/', label: 'Ogrodzenia', title: 'Ogrodzenia - PRO-MEN' },
  { path: '/akcesoria/', label: 'Akcesoria', title: 'Akcesoria - PRO-MEN' },
];

export default mainMenu;
