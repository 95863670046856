import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ButtonShadow = styled.div`
  display: inline-block;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0));
  transition: ${({ theme }) => theme.animations.default};

  &:hover {
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  }
`;

const ButtonBase = styled.div`
  min-width: ${({ variant }) => (variant === 'icon' ? 40 : 150)}px;
  height: ${({ variant }) => (variant === 'icon' ? '40px' : 'auto')};
  max-width: 100%;
  padding: ${({ variant }) => variant !== 'icon' && '.8em 1em .8em 1em'};
  border: none;
  color: white;
  font-size: 15px;
  background: ${({ theme, primary, disabled }) =>
    !disabled
      ? primary
        ? theme.colors.primary
        : theme.colors.secondary
      : '#a9a9a9'};
  cursor: pointer;
  transition: ${({ theme }) => theme.animations.default};

  &:active,
  &:focus {
    transform: scale(0.97);
  }
`;

const Button = ({
  className,
  variant = 'primary',
  children,
  style,
  component = 'button',
  baseStyles,
  ...rest
}) => {
  return (
    <ButtonShadow style={style} className={className}>
      <ButtonBase
        as={component}
        variant={variant}
        primary={variant === 'primary' ? 1 : 0}
        style={baseStyles}
        {...rest}
      >
        {children}
      </ButtonBase>
    </ButtonShadow>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'icon']),
  className: PropTypes.string,
  style: PropTypes.object,
  baseStyles: PropTypes.object,
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default styled(Button)``;
