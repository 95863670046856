// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-page-template-js": () => import("/opt/build/repo/src/templates/categoryPageTemplate.js" /* webpackChunkName: "component---src-templates-category-page-template-js" */),
  "component---src-templates-subpage-template-js": () => import("/opt/build/repo/src/templates/subpageTemplate.js" /* webpackChunkName: "component---src-templates-subpage-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("/opt/build/repo/src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-rodo-js": () => import("/opt/build/repo/src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

