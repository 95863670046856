import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerWrapper = styled.div`
  max-width: ${({ width }) => width}px;
  height: 100%;
  margin: 0 auto;
  padding: 0 25px;
  /* padding: 0px 1.0875rem 3rem 1.45rem; */
`;

const Container = ({ width, children, ...rest }) => (
  <ContainerWrapper width={width} {...rest}>
    {children}
  </ContainerWrapper>
);

Container.propTypes = {
  width: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Container.defaultProps = {
  width: 1410 + 50,
};

export default Container;
