/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('aos/dist/aos.css');

const AOS = require('aos');

AOS.init({
  once: true,
  offset: 0,
  delay: 0,
});

exports.shouldUpdateScroll = () => false;

exports.onRouteUpdate = () =>
  window.setTimeout(() => window.scrollTo(0, 0), 300);
