import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTag = styled.div`
  color: ${({ theme, color }) => theme.colors[color]};

  &.sectionTitle {
    color: ${({ theme, color }) => !color && theme.colors.primary};
    font-weight: 500;
    font-style: italic;
    padding-bottom: 0.2em;
  }
`;

const Typography = ({
  variant = 'p',
  color,
  sectionTitle = false,
  children,
  className = null,
  ...rest
}) => {
  const getClass = className
    ? sectionTitle
      ? { className: `sectionTitle ${className}` }
      : { className }
    : sectionTitle && { className: 'sectionTitle' };

  return (
    <StyledTag as={variant} color={color} {...rest} {...getClass}>
      {children}
    </StyledTag>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  sectionTitle: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Typography;
