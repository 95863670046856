import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Transition } from 'react-transition-group';

import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import './hamburger.css';
import mainMenu from '../../config/navigation';
import Button from '../button/button';
import theme, { media } from '../../config/theme';
import { NavMenu, NavItem as HeaderNavItem } from '../header/styles';

const SidebarContainer = styled.div`
  display: none;

  ${media.m`
    display: block;
  `}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  background: transparent;
  z-index: 199;
`;

const HamburgerWrapper = styled.div`
  position: fixed;
  top: 22px;
  right: 20px;
  z-index: 250;
`;

const Image = styled(Img)`
  width: 100%;
  margin-top: 15px;
`;

const SidebarBase = styled.aside`
  position: fixed;
  top: 0;
  padding: 15px 30px 40px;
  right: -280px;
  width: 280px;
  height: 100%;
  display: flex;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: white;
  background: #162022;
  /* box-shadow: inset 20px 0px 55px -45px rgba(0, 0, 0, 0.3); */
  z-index: 0;
  transition: 300ms ease-in-out;
`;

const VerticalMenu = styled(NavMenu)`
  flex-direction: column;
  padding-left: 15px;
`;

const NavItem = styled(HeaderNavItem)`
  font-size: 22px;
  margin: 10px 0;

  a {
    color: white;
  }
`;

const ContactLink = styled(NavItem)`
  display: flex;
  align-items: center;
  text-transform: none;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.primary};
`;

const duration = 500;

const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1, transform: 'translateX(0)' },
  entered: { opacity: 1 },
  exiting: {
    opacity: 0,
    transform: 'translateX(50px)',
  },
  exited: { opacity: 0 },
};

const query = graphql`
  query {
    file(name: { eq: "promen-logo" }) {
      childImageSharp {
        fluid(
          maxWidth: 150
          traceSVG: { threshold: 250, color: "hsl(45,93%,52%)" }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    prismicStronaKontakt {
      data {
        sekretariat {
          telefon_2
          email
        }
      }
    }
  }
`;

const Sidebar = ({ open, onHideSidebar, onToggleSidebar }) => {
  const {
    file: {
      childImageSharp: { fluid: logoFluid },
    },
    prismicStronaKontakt: {
      data: { sekretariat: sekretariatArr },
    },
  } = useStaticQuery(query);

  const [sekretariat] = sekretariatArr;

  const { telefon_2: phone, email } = sekretariat;

  return (
    <SidebarContainer>
      <HamburgerWrapper>
        <button
          className={[
            'hamburger',
            'hamburger--3dx',
            open ? 'is-active' : '',
          ].join(' ')}
          name="Otwórz menu"
          title="Otwórz menu"
          type="button"
          onClick={onToggleSidebar}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </HamburgerWrapper>

      <SidebarBase
        css={`
          transform: translateX(${open ? -280 : 0}px);
        `}
      >
        <Transition in={open} timeout={duration}>
          {state => (
            <Link
              to="/"
              title="Strona Główna - PRO-MEN"
              style={{
                width: 145,
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Image imgStyle={{ objectFit: 'contain' }} fluid={logoFluid} />
            </Link>
          )}
        </Transition>

        <Transition in={open} timeout={duration}>
          {state => (
            <VerticalMenu
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              css="margin: 40px 0;"
            >
              {mainMenu.map(({ path, label }) => (
                <NavItem key={path}>
                  <Link
                    to={path}
                    title={`${label} - PRO-MEN`}
                    onClick={onHideSidebar}
                    activeClassName="active"
                  >
                    {label}
                  </Link>
                </NavItem>
              ))}

              <NavItem>
                <NavItem>
                  <Link
                    to="/rodo/"
                    title="Polityka prywatności - PRO-MEN"
                    onClick={onHideSidebar}
                    activeClassName="active"
                  >
                    Polityka prywatności
                  </Link>
                </NavItem>
              </NavItem>
            </VerticalMenu>
          )}
        </Transition>

        <Transition in={open} timeout={duration}>
          {state => (
            <VerticalMenu
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <ContactLink href={`mailto: ${email}`}>
                <MailIcon
                  css={`
                    color: ${({ theme }) => theme.colors.primary};
                    font-size: 1em !important;
                    margin-right: 10px;
                  `}
                />
                {email}
              </ContactLink>

              <ContactLink href={`tel: ${phone}`}>
                <PhoneIcon
                  css={`
                    color: ${({ theme }) => theme.colors.primary};
                    font-size: 1em !important;
                    margin-right: 10px;
                  `}
                />
                {phone}
              </ContactLink>
            </VerticalMenu>
          )}
        </Transition>
      </SidebarBase>

      <Backdrop
        onTouchMove={onHideSidebar}
        onClick={onHideSidebar}
        css={`
          transform: translateX(${open ? 'calc(-100% - 290px)' : '0%'});
        `}
      />
    </SidebarContainer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onHideSidebar: PropTypes.func.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
};

export default Sidebar;
