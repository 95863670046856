import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, Transition } from 'react-transition-group';

import theme from '../../config/theme';

const timeout = 300;

const getTransitionStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
  },
  entered: {
    transition: `opacity ${theme.animations.default}`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${theme.animations.default}`,
    opacity: 0,
  },
};

const Fade = ({ location, children }) => (
  <TransitionGroup>
    <Transition
      key={location.pathname}
      timeout={{
        enter: timeout,
        exit: timeout,
      }}
    >
      {status => (
        <div
          style={{
            ...getTransitionStyles[status],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  </TransitionGroup>
);

Fade.propTypes = {
  location: PropTypes.object.isRequired,
  animate: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Fade;
