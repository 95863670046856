import { createGlobalStyle } from 'styled-components';
import 'typeface-roboto';

import { media } from '../config/theme';

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  html {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
  }

  body {
    overflow-y: initial !important;
  }

  :root {
    --cropSize: 110px;

    ${media.m`
      --cropSize: 80px;
    `}
  }

  .medium-zoom-overlay {
    z-index: 50;
  }

  .medium-zoom-image {
    z-index: 50
  }

  h1, .h1 {
    font-size: 2em;
    /* 2x body copy size = 32px */
    line-height: 1.25;
    font-weight: 600;
    /* 45px / 36px */
  }

  @media (min-width: 43.75em) {
    h1, .h1 {
      font-size: 2.5em;
      /* 2.5x body copy size = 40px */
      line-height: 1.125;
    }
  }

  @media (min-width: 56.25em) {
    h1, .h1 {
      font-size: 3em;
      /* 3x body copy size = 48px */
      line-height: 1.05;
      /* keep to a multiple of the 20px line height and something more appropriate for display headings */
    }
  }

  h2, .h2 {
    font-size: 1.625em;
    /* 1.625x body copy size = 26px */
    line-height: 1.15384615;
    /* 30px / 26px */
  }

  @media (min-width: 43.75em) {
    h2, .h2 {
      font-size: 2em;
      /* 2x body copy size = 32px */
      line-height: 1.25;
    }
  }

  @media (min-width: 56.25em) {
    h2, .h2 {
      font-size: 2.25em;
      /* 2.25x body copy size = 36px */
      line-height: 1.25;
    }
  }

  h3, .h3 {
    font-size: 1.375em;
    /* 1.375x body copy size = 22px */
    line-height: 1.13636364;
    /* 25px / 22px */
    font-weight: 700;
  }

  @media (min-width: 43.75em) {
    h3, .h3 {
      font-size: 1.5em;
      /* 1.5x body copy size = 24px */
      line-height: 1.25;
    }
  }

  @media (min-width: 56.25em) {
    h3, .h3 {
      font-size: 1.75em;
      /* 1.75x body copy size = 28px */
      line-height: 1.25;
    }
  }

  h4, .h4 {
    font-size: 1.125em;
    /* 1.125x body copy size = 18px */
    line-height: 1.11111111;
  }

  @media (min-width: 43.75em) {
    h4, .h4 {
      line-height: 1.22222222;
      /* (22px / 18px */
    }
  }

  p {
    line-height: 1.5em;
    font-weight: 300;
  }

  ul {
    margin: 1.2em 0;
    line-height: 1.8em;
    font-weight: 300;
    list-style-type: square;

    & > li {
      margin-left: 1em;
    }
  }

  strong {
    font-weight: bold;
  }

  a {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
  }
`;

export default GlobalStyle;
