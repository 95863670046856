import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Reset } from 'styled-reset';

import Header from '../header/header';
import Fade from '../fade/fade';
import GlobalStyle from '../../styles/globalStyles';
import theme from '../../config/theme';
import Sidebar from '../sidebar/sidebar';

const Main = styled.main`
  transition: 300ms ease-in-out;
  /* overflow-x: hidden; */
`;

// Pages that don't have sticky side navigation
const overflowPages = ['/', '/o-nas', '/kontakt'];

const Layout = ({ location, children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const onResize = useCallback(() => {
    if (window.outerWidth > theme.breakpoints.m) {
      setShowSidebar(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Reset />

        <GlobalStyle />

        <Header
          css={`
            transform: translateX(${showSidebar ? -280 : 0}px);
            transition: 300ms ease-in-out;
          `}
        />

        <Sidebar
          open={showSidebar}
          onHideSidebar={() => setShowSidebar(false)}
          onToggleSidebar={() => setShowSidebar(!showSidebar)}
        />

        <Main
          css={`
            transform: translateX(${showSidebar ? -280 : 0}px);
            overflow-x: ${overflowPages.includes(location.pathname)
              ? 'hidden'
              : 'initial'};
          `}
        >
          <Fade location={location}>{children}</Fade>
        </Main>
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
